import { Field as ReactField, useField } from 'react-final-form';
import { t, translate, getLanguage } from 'react-switch-lang';
import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import 'react-datepicker/dist/react-datepicker.css';
import Image from 'next/legacy/image';
import { endOfToday } from 'date-fns';
import style from '../styles/Field.module.scss';
import Button from './Button';
import { validateDate } from '../utils/Validation';
import { formatDate, formatDateTime } from '../utils/Format';
import DatePicker from './DatePicker';
import { ExternalLink } from './Link';
import ReplaceTextWithElement from './ReplaceTextWithElement';
import { events } from '../utils/Amplitude';
import { parseDateTimeToString, parseStringToDateTime } from '../utils/ReportsHelpers';

export function Label({ text, id, name, optional, formatText, formatTextFullOpacity }) {
  return (
    <label htmlFor={id} id={`${name}Lbl`}>
      {text}
      {optional && <span className={style.optionalLbl}>{`(${t('Form_Lbl_Optional')})`}</span>}
      {formatText && (
      <p className={formatTextFullOpacity ? style.formatLblFullOpacity : style.formatLbl}>
        {formatText}
      </p>
      )}
    </label>
  );
}

export function parseStringToDate(dateStr) {
  if (validateDate(dateStr)) return null;

  const d = new Date();
  const ar = dateStr.split('-');
  d.setFullYear(parseInt(ar[0], 10), parseInt(ar[1], 10) - 1, parseInt(ar[2], 10));
  return d;
}

export function parseDateToString(date) {
  if (!(date instanceof Date)) return '';
  const month = `${date.getMonth() + 1}`.padStart(2, '0');
  const day = `${date.getDate()}`.padStart(2, '0');
  const year = date.getFullYear();

  return [year, month, day].join('-');
}

export const parseDateTimeToStringStatic = (dateTime, endOfDay = false) => {
  if (!(dateTime instanceof Date)) return '';
  const month = `${dateTime.getMonth() + 1}`.padStart(2, '0');
  const day = `${dateTime.getDate()}`.padStart(2, '0');
  const year = dateTime.getFullYear();

  return `${[year, month, day].join('-')} ${endOfDay ? '23:59' : '00:00'}`;
};

export function Default({
  id,
  name,
  label,
  placeholder = '',
  helpText,
  formatText,
  initialValue = '',
  format = undefined,
  activeFormat = true, // formats on input rather than just on blur
  parse = activeFormat ? format : undefined,
  autoComplete = '',
  className = '',
  optional = false,
  disabled = false,
  prefixItem,
  suffixItem,
  fixAlignment = false,
  fieldClassName = '',
  inputGroupClassname = '',
  fixBusOwnerAlignment = false,
  prefixURL,
  formatTextFullOpacity = false,
  submittedValueWarning = '', // shown if input value is not changed since last submit
  validate,
}) {
  const { input, meta } = useField(name, {
    initialValue, formatOnBlur: !!format, format, parse, validate,
  });

  const fieldState = useMemo(() => {
    if (!meta.touched) return '';
    if (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)) return 'is-invalid';
    if (submittedValueWarning && !meta.dirtySinceLastSubmit) return 'is-warning';
    return 'is-valid';
    // eslint-disable-next-line max-len
  }, [meta.touched, meta.error, meta.submitError, submittedValueWarning, meta.dirtySinceLastSubmit]);

  const inputClassNames = useMemo(() => ([
    prefixURL && style.prefixURLInput,
    disabled && style.disabledField,
    fieldClassName,
    'form-control',
    fieldState,
    input.value === '' && 'is-empty',
  ].filter((v) => v).join(' ')), [prefixURL, disabled, fieldClassName, fieldState, input.value]);

  return (
    <div className={`${className} ${style.formField}`}>
      <Label
        text={label}
        name={name}
        optional={optional}
        id={id}
        formatText={formatText}
        formatTextFullOpacity={formatTextFullOpacity}
      />
      <div className={`${(prefixItem || suffixItem ? 'input-group' : '')} ${fixAlignment ? style.fixFieldAlignment : ''} ${fixBusOwnerAlignment ? style.fixBusOwnerAlignment : ''} ${inputGroupClassname}`}>
        {prefixItem && <span className={`${prefixURL ? style.prefixURL : style.inputGroupText} input-group-text`}>{prefixItem}</span>}
        <input
          {...input}
          id={id}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          autoComplete={autoComplete}
          className={inputClassNames}
        />
        {suffixItem && <span className={`${style.inputGroupText} input-group-text`}>{suffixItem}</span>}
      </div>
      {fieldState === 'is-invalid' && <span id={`${id}Error`} className={`${style.errorTxt} invalid-feedback`}>{meta.error || meta.submitError}</span>}
      {fieldState === 'is-warning' && <span id={`${id}Error`} className={`${style.errorTxt} warning-feedback`}>{submittedValueWarning}</span>}

      {helpText && <p className={style.helpTxt}>{helpText}</p>}
    </div>
  );
}

function PasswordField({
  id,
  name,
  label,
  placeholder,
  formatText,
  showRules = false,
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <ReactField name={name}>
      {({ input, meta }) => (
        <div className={`${style.formField} ${style.password}`}>
          <div className={style.passwordLblDiv}>
            <Label text={label} id={id} name={name} />
          </div>
          {formatText && <p className={style.formatLbl}>{formatText}</p>}

          <div className={style.passwordFieldContainer}>
            <input
              {...input}
              type={showPassword ? 'text' : 'password'}
              id={id}
              name={name}
              placeholder={placeholder}
              className={`form-control ${(meta.touched || '') && (meta.error || meta.submitError ? 'is-invalid' : 'is-valid')}`}
              autoComplete={showRules ? 'new-password' : 'current-password'}
            />
            <button
              type="button"
              title={
                showPassword ? t('LinkDescription_HidePassword') : t('LinkDescription_ShowPassword')
              }
              aria-label={
                showPassword ? t('LinkDescription_HidePassword') : t('LinkDescription_ShowPassword')
              }
              className={`${style.passwordShowToggle}`}
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              <FontAwesomeIcon icon={faEye} size="lg" className={showPassword ? 'd-none' : ''} />
              <FontAwesomeIcon
                icon={faEyeSlash}
                size="lg"
                className={!showPassword ? 'd-none' : ''}
              />
            </button>
          </div>

          {(meta.error || meta.submitError) && meta.touched && (
            <span id="passwordError" className={`${style.errorTxt} invalid-feedback`}>
              {meta.error || meta.submitError}
            </span>
          )}

          {showRules && (
            <>
              <p className={style.helpTxt}>{t('Auth_Field_Password_Help')}</p>
              <ul className={style.helpTxt}>
                <li>{t('Auth_Field_Password_Help_Length')}</li>
                <li>{t('Auth_Field_Password_Help_SpecialChar')}</li>
                <li>{t('Auth_Field_Password_Help_UppercaseChar')}</li>
                <li>{t('Auth_Field_Password_Help_Number')}</li>
              </ul>
            </>
          )}
        </div>
      )}
    </ReactField>
  );
}
export const Password = translate(PasswordField);

export function Checkbox({
  id,
  name,
  label,
  initialValue,
  className = '',
  showAlert = false,
  errorMsg = t('Required_Lbl'),
  description,
  inline = false,
  disabled = false,
}) {
  return (
    <ReactField name={name} initialValue={initialValue} type="checkbox">
      {({ input, meta }) => (
        <div className={`${className} ${disabled ? style.disabledCheckbox : ''} ${style.formCheckbox} form-check ${inline ? 'form-check-inline' : ''}`}>
          <input
            {...input}
            className={`form-check-input ${(meta.touched || '') && (meta.error || meta.submitError ? 'is-invalid' : '')}`}
            type="checkbox"
            id={id}
            disabled={disabled}
            name={name}
          />
          <div className="chkLabelContainer">
            <label htmlFor={id} className="form-check-label">
              {showAlert && (meta.error || meta.submitError) && meta.touched && (
                <span id={`${id}Error`} className="errorTxt invalid-feedback upperCase">
                  {`(${errorMsg})`}
                  {' '}
                </span>
              )}
              {label}
              {description}
            </label>
          </div>
        </div>
      )}
    </ReactField>
  );
}

export function Radio({
  id,
  name,
  label,
  className = '',
  initialValue,
  showAlert = false,
  errorMsg = t('Required_Lbl'),
  description,
  inline = false,
  value,
}) {
  return (
    <ReactField name={name} initialValue={initialValue} value={value} type="radio">
      {({ input, meta }) => (
        <div className={`${className} ${style.formCheckbox} form-check ${inline ? 'form-check-inline' : ''}`}>
          <input
            {...input}
            className={`form-check-input ${(meta.touched || '') && (meta.error || meta.submitError ? 'is-invalid' : '')}`}
            type="radio"
            id={id}
            name={name}
          />
          <div className="chkLabelContainer">
            <label htmlFor={id} className="form-check-label">
              {showAlert && (meta.error || meta.submitError) && meta.touched && (
                <span id={`${id}Error`} className="errorTxt invalid-feedback upperCase">
                  {`(${errorMsg})`}
                  {' '}
                </span>
              )}
              {label}
              {description}
            </label>
          </div>
        </div>
      )}
    </ReactField>
  );
}

export function PaymentOption({
  id,
  name,
  title,
  processingTime,
  fee,
  dollarFee = null,
  feeLink = '',
  feeAmpEvent,
  feeAriaLabel,
  className = '',
  initialValue,
  showAlert = false,
  errorMsg = t('Required_Lbl'),
  description,
  subTitle = null,
  inline = false,
  children,
}) {
  return (
    <ReactField name={name} initialValue={initialValue} type="checkbox">
      {({ input, meta }) => (
        <div className={`${className} ${style.formCheckbox} ${style.paymentOptionCheckbox} form-check ${inline ? 'form-check-inline' : ''}`}>
          <div className={style.paymentOptionLabelCont}>
            <label htmlFor={id} className="form-check-label">
              {showAlert && (meta.error || meta.submitError) && meta.touched && (
                <div>
                  <span id={`${id}Error`} className="errorTxt invalid-feedback upperCase">
                    {`(${errorMsg})`}
                    {' '}
                  </span>
                </div>
              )}
              <div>
                <div className={style.paymentOptionCheckboxTitleContainer}>
                  <h3>
                    <ReplaceTextWithElement
                      text={title}
                      replaceWith={<i>Interac</i>}
                      replaceKey="Interac"
                    />
                  </h3>
                  <div className={style.paymentOptionCheckboxLogos}>{children}</div>
                </div>
                <div className={style.paymentOptionCheckboxText}>
                  <p>{description}</p>
                  {subTitle && <p className={style.paymentOptionSubTitle}>{subTitle}</p>}
                </div>
                <p className={style.paymentOptionProcessingFee}>
                  <span className={style.paymentOptionBold}>{t('DetailsForm_PaymentOptions_ProcessingTime_Label')}</span>
                  {` ${processingTime === 1 ? t('DetailsForm_PaymentOptions_1BusinessDay') : t('DetailsForm_PaymentOptions_nBusinessDay', { days: processingTime })}`}
                </p>
                <p>
                  <span className={style.paymentOptionBold}>{t('DetailsForm_PaymentOptions_Fee_Label')}</span>
                  {dollarFee ? (
                    <span>
                      {` ${dollarFee} `}
                      <ExternalLink
                        href={feeLink}
                        ampEvent={feeAmpEvent}
                        aria-label={feeAriaLabel}
                        text={t('DetailsForm_PaymentOptions_FeeHelpLink')}
                      />
                    </span>
                  ) : (<span>{` ${fee.toFixed(1)}%`}</span>)}
                </p>
              </div>
            </label>
          </div>
          <input
            {...input}
            className={`form-check-input ${(meta.touched || '') && (meta.error || meta.submitError ? 'is-invalid' : '')}`}
            type="checkbox"
            id={id}
            name={name}
          />
        </div>
      )}
    </ReactField>
  );
}

export function DateField({
  id,
  name,
  label,
  placeholder = t('Form_Date_Format'),
  helpText,
  formatText = t('Form_Date_Format'),
  initialValue = '',
  maxDate,
  format = formatDate,
  parse = format,
  className = '',
  optional = false,
  setValue,
}) {
  const [isOpen, setOpen] = useState(false);
  const [stateOnMouseDown, setStateOnMouseDown] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    parseStringToDate(initialValue) || maxDate || new Date()
  );

  useEffect(() => {
    if (isOpen) {
      const el = document.querySelector('.react-datepicker');
      el.setAttribute('tabIndex', -1);
      el.focus();
    }
  }, [isOpen]);

  return (
    <ReactField
      name={name}
      initialValue={initialValue}
      formatOnBlur={!!format}
      format={format}
      parse={parse}
    >
      {({ input, meta }) => (
        <div className={`${className} ${style.formField}`}>
          <Label text={label} name={name} optional={optional} id={id} formatText={formatText} />
          <div className="input-group">
            <input
              {...input}
              onChange={(e) => {
                const d = parseStringToDate(e.target.value);
                if (d) setSelectedDate(d);
                input.onChange(e);
              }}
              id={id}
              name={name}
              placeholder={placeholder}
              className={`form-control ${(meta.touched || '') && (meta.error || meta.submitError ? 'is-invalid' : 'is-valid')} ${input.value === '' ? 'is-empty' : ''}`}
            />
            <DatePicker
              onChange={(d) => {
                setSelectedDate(d);
                setValue(name, parseDateToString(d));
              }}
              autoComplete="off"
              dateFormat="yyyy-MM-dd"
              showMonthDropdown
              showYearDropdown
              preventOpenOnFocus
              autoFocus={false}
              autoCorrect={false}
              dropdownMode="select"
              placeholderText={placeholder}
              selected={selectedDate}
              open={isOpen}
              onClickOutside={() => setOpen(false)}
              onSelect={() => setOpen(false)}
              className="form-control"
              tabIndex="-1"
              disabled
              locale={getLanguage()}
              maxDate={maxDate}
            />
            <Button
              className="datepickerBtn"
              type="button"
              title={t('ButtonDescription_Datepicker')}
              // needed to cancel out the effects of onClickOutside
              onMouseDown={() => setStateOnMouseDown(isOpen)}
              onClick={() => {
                if (stateOnMouseDown) {
                  // if popup was open on mouse down, explicitly set isOpen to false
                  // (toggle won't work because OnClickOutside is triggered first)
                  setOpen(false);
                  setStateOnMouseDown(false);
                } else {
                  setOpen(!isOpen);
                }
              }}
            >
              <FontAwesomeIcon icon={faCalendarAlt} />
            </Button>
          </div>

          {(meta.error || meta.submitError) && meta.touched && (
            <span id={`${id}Error`} className={`${style.errorTxt} invalid-feedback`}>{meta.error || meta.submitError}</span>
          )}

          {helpText && <p className="helpTxt">{helpText}</p>}
        </div>
      )}
    </ReactField>
  );
}

function DropdownField({
  id,
  name,
  label,
  placeholder,
  helpText,
  initialValue,
  autoComplete = undefined,
  className = '',
  values,
  optional = false,
  labelWithMargin = false,
  disabled = false,
  onChange = undefined,
  noCode = false,
}) {
  return (
    <ReactField name={name} initialValue={initialValue}>
      {({ input, meta }) => (
        <div className={`${className} ${style.formField}`}>
          <Label
            text={label}
            name={name}
            optional={optional}
            id={id}
            labelWithMargin={labelWithMargin}
          />

          <select
            {...input}
            id={id}
            disabled={disabled}
            name={name}
            className={`${style.formSelect} form-select ${(meta.touched || '') && (meta.error || meta.submitError ? 'is-invalid' : 'is-valid')} 
              ${input.value === '' ? 'is-empty' : ''}`}
            autoComplete={autoComplete}
            onChange={(e) => {
              onChange?.(e);
              input.onChange(e);
            }}
          >
            <option value="">{placeholder}</option>
            {values?.map(({ French, English, Code }) => (
              <option value={noCode ? English : Code} key={Code}>{getLanguage() === 'fr' ? French : English}</option>
            ))}
          </select>

          {(meta.error || meta.submitError) && meta.touched && (
            <span id={`${id}Error`} className="errorTxt invalid-feedback">{meta.error || meta.submitError}</span>
          )}

          {helpText && <p className="helpTxt">{helpText}</p>}
        </div>
      )}
    </ReactField>
  );
}
export const Dropdown = translate(DropdownField);

export function CheckboxGroup({
  id,
  name,
  initialValue = '',
  format = undefined,
  parse = format,
  className = '',
  children,
}) {
  return (
    <ReactField
      name={name}
      initialValue={initialValue}
      formatOnBlur={!!format}
      format={format}
      parse={parse}
    >
      {({ meta }) => (
        <div className={`${className} ${style.formField} ${style.formFieldGroup}`} name={name}>
          {children}
          {(meta.error || meta.submitError) && meta.touched && (
          <span id={`${id}Error`} className={`${style.errorTxt} invalid-feedback`}>{meta.error || meta.submitError}</span>
          )}
        </div>
      )}
    </ReactField>
  );
}

export function LogoFile({
  id,
  name,
  label,
  autoComplete = '',
  className = '',
  optional = false,
  formatText,
  initialValue = '',
  accept = 'image/png, .jpeg, .jpg',
  removedFileBtnCallback, // parent call back function for logo image field
}) {
  const [busLogoImage, setBusLogoImage] = useState('');
  const [invalidFIleType, setInvalidFIleType] = useState(false);
  const acceptedFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
  const logoUrl = initialValue;

  const resetOnClickInputFile = (e) => {
    e.target.value = null;
  };

  // Change text beside logo image in Preferences
  // If logo url doesn't exist or logo hasn't been uploaded then display no file chosen
  // if logo image has been uploaded then display file name
  // Other cases hide text
  function displayLogoFileNameHandler() {
    const file = document.getElementById(id)?.files[0];
    if (!file) return null;
    return (
      <p className={`${style.fileName} ${style.logoFileName}`}>{file?.name}</p>
    );
  }

  useEffect(() => {
    if (logoUrl !== null || logoUrl !== undefined || logoUrl !== '') {
      setBusLogoImage(logoUrl);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ReactField
      name={name}
    >
      {({ input: { value, onChange, ...input }, meta }) => (
        <div className={`${className} ${style.formField} ${style.fileField}`}>
          <Label text={label} name={name} optional={optional} formatText={formatText} />
          <div>
            {(busLogoImage && !invalidFIleType) ? (
              <div className={style.busLogoImageDisplay}>
                <Image
                  className={style.busLogoImage}
                  alt={t('Logo_Field_Alt_Text_Example')}
                  src={busLogoImage}
                  width={100}
                  height={100}
                  unoptimized
                />
                <Button
                  className={style.removeFileButton}
                  title={t('ButtonDescription_RemoveFile')}
                  ampEvent={events.USER_DELETED_UPLOADED_FILE_BUTTON}
                  onClick={() => {
                    setBusLogoImage('');
                    removedFileBtnCallback();
                  }}
                >
                  <FontAwesomeIcon
                    icon={faClose}
                    size="lg"
                  />
                </Button>
              </div>
            ) : null}
            {displayLogoFileNameHandler()}
            <div className={`${className} input-group ${style.fileContainer}`}>
              <Button
                name={name}
                text={busLogoImage ?
                  t('DetailsForm_Preferences_ChangeLogo_Btn') : t('DetailsForm_Preferences_Select_File_Btn')}
                onClick={() => document.getElementById('LogoFileInput').click()}
                ariaLabel={t('ButtonDescription_SelectLogoFile')}
                ampEvent={events.USER_CLICKED_SELECT_FILE_BUTTON}
                linkBtn={busLogoImage}
              />
              <p className={style.fileName}>{busLogoImage === '' ? t('DetailsForm_Preferences_NoFileChosen') : ''}</p>
            </div>
            <input
              {...input}
              id={id}
              type="file"
              accept={accept}
              hidden="hidden"
              className={style.formLogoFieldInput}
              onChange={({ target }) => {
                if (Object.keys(target.files).length === 0) {
                  onChange(null);
                  return;
                }
                onChange(target.files);
                setBusLogoImage(URL.createObjectURL(target.files[0]));
                if (acceptedFileTypes.every((fileType) => fileType !== target.files[0].type)) {
                  setInvalidFIleType(true);
                } else {
                  setInvalidFIleType(false);
                }
              }}
              onClick={resetOnClickInputFile}
              autoComplete={autoComplete}
            />
          </div>

          {(meta.error || meta.submitError) && meta.touched && (
          <span id={`${id}Error`} className={`${style.errorTxt} invalid-feedback`}>{meta.error || meta.submitError}</span>
          )}
        </div>
      )}
    </ReactField>
  );
}

export function File({
  id,
  name,
  label,
  autoComplete = '',
  className = '',
  optional = false,
  formatText,
  accept = 'image/png, .jpeg, .jpg',
  ariaLabel,
  validate,
  children,
}) {
  return (
    <ReactField
      name={name}
      validate={validate}
    >
      {({ input: { value, onChange, ...input }, meta }) => (
        <div className={`${className} ${style.formField} ${style.fileField}`}>
          <Label text={label} optional={optional} name={name} formatText={formatText} />
          <div>
            {children || (
              <div className={`${className} input-group ${style.fileContainer}`}>
                <Button
                  name={name}
                  className="fileButton"
                  text={t('DetailsForm_Preferences_Select_File_Btn')}
                  onClick={() => document.getElementById('FileInput').click()}
                  ariaLabel={ariaLabel}
                  ampEvent={events.USER_CLICKED_SELECT_FILE_BUTTON}
                />
                <p className={style.fileName}>{value ? value[0].name : t('DetailsForm_Preferences_NoFileChosen')}</p>
              </div>
            )}
            <input
              {...input}
              id={id}
              type="file"
              accept={accept}
              hidden="hidden"
              onChange={({ target }) => {
                if (Object.keys(target.files).length === 0) {
                  onChange(null);
                  return;
                }
                onChange(target.files);
              }}
              autoComplete={autoComplete}
            />
          </div>

          {(meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)) && meta.touched && (
          <span id={`${id}Error`} className={`${style.errorTxt} invalid-feedback`}>{meta.error || meta.submitError}</span>
          )}
        </div>
      )}
    </ReactField>
  );
}

export function TextArea({
  id,
  name,
  label,
  placeholder = '',
  helpText,
  formatText,
  initialValue = '',
  format = undefined,
  parse = format,
  autoComplete = '',
  className = '',
  optional = false,
  disabled = false,
  prefixItem,
  suffixItem,
  fixAlignment = false,
  fieldClassName = '',
  inputGroupClassname = '',
}) {
  return (
    <ReactField
      name={name}
      initialValue={initialValue}
      formatOnBlur={!!format}
      format={format}
      parse={parse}
    >
      {({ input, meta }) => (
        <div className={`${className} ${style.formField} ${style.textAreaField}`}>
          <Label text={label} name={name} optional={optional} id={id} formatText={formatText} />

          <div className={`${(prefixItem || suffixItem ? 'input-group' : '')} ${fixAlignment ? style.fixFieldAlignment : ''} ${inputGroupClassname}`}>
            { prefixItem &&
              <span className={`${style.inputGroupText} input-group-text`}>{prefixItem}</span>}
            <textarea
              {...input}
              id={id}
              name={name}
              disabled={disabled}
              placeholder={placeholder}
              autoComplete={autoComplete}
              className={`${style.textAreaInput} ${disabled ? style.disabledField : ''} ${fieldClassName || ''} form-control ${(meta.touched || '') && (meta.error || meta.submitError ? 'is-invalid' : 'is-valid')} ${input.value === '' ? 'is-empty' : ''}`}
            />
            { suffixItem &&
              <span className={`${style.inputGroupText} input-group-text`}>{suffixItem}</span>}
          </div>

          {(meta.error || meta.submitError) && meta.touched && (
          <span id={`${id}Error`} className={`${style.errorTxt} invalid-feedback`}>{meta.error || meta.submitError}</span>
          )}

          {helpText && <p className={style.helpTxt}>{helpText}</p>}
        </div>
      )}
    </ReactField>
  );
}

export function DateRangeField({
  idStart,
  idEnd,
  nameStart,
  nameEnd,
  labelStart,
  labelEnd,
  includeTime = false,
  timeLabel,
  placeholder = includeTime ? t('Form_DateTime_Format') : t('Form_Date_Format'),
  helpText,
  formatText = includeTime ? t('Form_DateTime_Format') : t('Form_Date_Format'),
  initialStartValue = '',
  initialEndValue = '',
  maxDate,
  minDate,
  format = includeTime ? formatDateTime : formatDate,
  parse = format,
  optional = false,
  setValue,
  contClassName = '',
  startDateClassName = '',
  endDateClassName = '',
  onChange = undefined,
}) {
  const [isOpen, setOpen] = useState(false);
  const [stateOnMouseDown, setStateOnMouseDown] = useState(false);
  const parseStringToDateObject = includeTime ? parseStringToDateTime : parseStringToDate;
  const [selectedStartDate, setSelectedStartDate] = useState(
    parseStringToDateObject(initialStartValue) || maxDate || new Date()
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    parseStringToDateObject(initialEndValue) || maxDate || new Date()
  );

  useEffect(() => {
    if (isOpen) {
      const el = document.querySelector('.react-datepicker');
      el.setAttribute('tabIndex', -1);
      el.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedEndDate) setOpen(false);
  }, [selectedEndDate]);

  const setEndDateToToday = () => {
    setSelectedEndDate(endOfToday());
    setValue(
      nameEnd,
      includeTime ?
        parseDateTimeToString(endOfToday()) :
        parseDateToString(endOfToday())
    );
  };

  return (
    <div className={contClassName}>
      <div className={style.dateRangeButtonCont}>
        <div className={`${style.formField} dateRange ${selectedStartDate && !selectedEndDate ? ' enableTodayBtn' : ''}`}>
          <Button
            fill="outline"
            className={style.dateRangeButton}
            type="button"
            title={t('ButtonDescription_DatepickerRange')}
            ampEvent={events.USER_CLICKED_DATEPICKER_BUTTON}
            // needed to cancel out the effects of onClickOutside
            onMouseDown={() => setStateOnMouseDown(isOpen)}
            onClick={() => {
              if (stateOnMouseDown) {
                // if popup was open on mouse down, explicitly set isOpen to false
                // (toggle won't work because OnClickOutside is triggered first)
                setOpen(false);
                setStateOnMouseDown(false);
              } else {
                setOpen(!isOpen);
              }
            }}
          >
            <FontAwesomeIcon icon={faCalendarAlt} />
          </Button>
          <DatePicker
            onChange={(dates) => {
              onChange?.(dates);
              const [startDate, endDate] = dates;
              setSelectedStartDate(startDate);
              setSelectedEndDate(endDate);
              setValue(
                nameStart,
                (includeTime ?
                  parseDateTimeToStringStatic(startDate) :
                  parseDateToString(startDate))
              );
              setValue(
                nameEnd,
                (includeTime ?
                  parseDateTimeToStringStatic(endDate, true) : parseDateToString(endDate))
              );
            }}
            onSelect={(d) => {
              onChange?.(d);
              if (!selectedStartDate) {
                setSelectedStartDate(d);
                setSelectedEndDate(null);
                setValue(
                  nameStart,
                  (includeTime ? parseDateTimeToStringStatic(d) : parseDateToString(d))
                );
                setValue(
                  nameEnd,
                  (includeTime ? parseDateTimeToString(null) : parseDateToString(null))
                );
              }
            }}
            autoComplete="off"
            dateFormat="yyyy-MM-dd"
            showMonthDropdown
            showYearDropdown
            timeInputLabel={timeLabel}
            selectsRange
            preventOpenOnFocus
            autoFocus={false}
            autoCorrect={false}
            dropdownMode="select"
            placeholderText={placeholder}
            selected={selectedStartDate}
            startDate={selectedStartDate}
            endDate={selectedEndDate}
            openToDate={selectedEndDate}
            todayButton={t('DateFilter_SelectTodayBtn')}
            open={isOpen}
            popperClassName={style.dateRangePicker}
            onClickOutside={() => setOpen(false)}
            className="form-control"
            tabIndex="-1"
            disabled
            locale={getLanguage()}
            maxDate={maxDate}
            minDate={minDate}
            popperPlacement="bottom-start"
          />
        </div>
      </div>
      <div className={startDateClassName}>
        <ReactField
          name={nameStart}
          initialValue={initialStartValue}
          formatOnBlur={!!format}
          format={format}
          parse={parse}
        >
          {({ input, meta }) => (
            <div className={style.formField}>
              <Label
                text={labelStart}
                name={nameStart}
                optional={optional}
                id={idStart}
                formatText={formatText}
              />
              <div className="input-group">
                <input
                  {...input}
                  onChange={(e) => {
                    onChange?.(e);
                    const d = parseStringToDateObject(e.target.value);
                    if (d) setSelectedStartDate(d);
                    input.onChange(e);
                  }}
                  id={idStart}
                  name={nameStart}
                  placeholder={placeholder}
                  className={`form-control ${(meta.touched || '') && (meta.error || meta.submitError ? 'is-invalid' : 'is-valid')} ${input.value === '' ? 'is-empty' : ''}`}
                />
              </div>
              {(meta.error || meta.submitError) && meta.touched && (
              <span id={`${idStart}Error`} className={`${style.errorTxt} invalid-feedback grid-daterange-error`}>{meta.error || meta.submitError}</span>
              )}
              {helpText && <p className="helpTxt">{helpText}</p>}
            </div>
          )}
        </ReactField>
      </div>
      <div className={endDateClassName}>
        <ReactField
          name={nameEnd}
          initialValue={initialEndValue}
          formatOnBlur={!!format}
          format={format}
          parse={parse}
        >
          {({ input, meta }) => (
            <div className={style.formField}>
              <Label
                text={labelEnd}
                name={nameEnd}
                optional={optional}
                id={idEnd}
                formatText={formatText}
              />
              <div className="input-group">
                <input
                  {...input}
                  onChange={(e) => {
                    onChange?.(e);
                    const d = parseStringToDateObject(e.target.value);
                    if (d) setSelectedEndDate(d);
                    input.onChange(e);
                  }}
                  onBlur={(e) => {
                    if (e.target.value > parseDateTimeToString(endOfToday())) setEndDateToToday();
                    input.onBlur(e);
                  }}
                  id={idEnd}
                  name={nameEnd}
                  placeholder={placeholder}
                  className={`form-control ${(meta.touched || '') && (meta.error || meta.submitError ? 'is-invalid' : 'is-valid')} ${input.value === '' ? 'is-empty' : ''}`}
                />

              </div>

              {(meta.error || meta.submitError) && meta.touched && (
              <span id={`${idEnd}Error`} className={`${style.errorTxt} invalid-feedback`}>{meta.error || meta.submitError}</span>
              )}

              {helpText && <p className="helpTxt">{helpText}</p>}
            </div>
          )}
        </ReactField>
      </div>
    </div>
  );
}
