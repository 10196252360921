import { getLanguage } from 'react-switch-lang';

export const formatPostalCode = (value) => (!value ?
  '' :
  value.toUpperCase().replace(/[^A-Z0-9]/g, '').slice(0, 6).replace(/(...)(.)/, '$1 $2'));

export const formatVanityUrl = (value) => (!value ?
  '' :
  value.toLowerCase().replace(/[^a-z]/g, ''));

export const formatDate = (value) => (!value ?
  '' :
  value.replace(/[^\d-]/g, '')
    .replace(/^(.{10})(.*)$/g, '$1')
    .replace(/^(\d{4})(\d{1})$/, '$1-$2')
    .replace(/^(\d{4})-(\d{2})(\d{1})$/, '$1-$2-$3')
    .replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3'));

export const formatDateTime = (value) => (!value ?
  '' :
  // formats to YYYY-MM-DD HH:mm
  value.replace(/[^\d:-\s]/g, '')
    .replace(/^(.{16})(.*)$/g, '$1')
    .replace(/^(\d{4})(\d{1})$/, '$1-$2')
    .replace(/^(\d{4})-(\d{2})(\d{1})$/, '$1-$2-$3')
    .replace(/^(\d{4})-(\d{2})-(\d{2})(\d{1})/, '$1-$2-$3 $4')
    .replace(/^(\d{4})-(\d{2})-(\d{2}) (\d{2})(\d{1})/, '$1-$2-$3 $4:$5')
    .replace(/^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})$/, '$1-$2-$3 $4:$5'));

export const formatBusinessOwnershipPercentage = (value) => (
  !value || Number.isNaN(Number.parseFloat(value)) ?
    value : Number.parseFloat(value).toString().replace(/(\.\d{2})\d+$/, '$1')
);

export const formatBusinessNumber = (value) => (!value ?
  '' :
  value.replace(/[^\d-]/g, '')
    .replace(/^(.{9})(.*)$/g, '$1'));

export const formatPhoneNumber = (value) => (!value ?
  '' :
  value.replace(/[^\d-]/g, '')
    .replace(/^(.{12})(.*)$/g, '$1')
    .replace(/^(\d{3})(\d{1})$/, '$1-$2')
    .replace(/^(\d{3})-(\d{3})(\d{1})$/, '$1-$2-$3')
    .replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3'));

export const formatAmountEn = (value) => {
  if (!value) return '';
  if (/^\.$/.test(value)) return '0.';
  // remove all dollar signs, but leave one at the front if present
  return `${/^\$/.test(value) ? '$' : ''}${value.replaceAll('$', '')}`.replace(/[^0-9,.$]/g, '');
};

export const formatAmountFr = (value) => {
  if (!value) return '';
  if (/^,$/.test(value)) return '0,';
  // remove all dollar signs, but leave one at the end if present
  return `${value.replaceAll('$', '').replace(/[^0-9,$\s]/g, '')}${/\$$/.test(value) ? '$' : ''}`;
};

export const formatAmountWhole = (value) => {
  const lang = getLanguage();
  if (!value) return '';
  let input = value.replace(/[\D\s.,_-]+/g, '');
  input = input ? parseInt(input, 10) : 0;
  return (input === 0) ? '' : input.toLocaleString(`${lang}-CA`);
};
